import { Component, OnDestroy } from '@angular/core';
import { ThemesService } from '@shared/themes.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Theme } from '@shared/theme.enum.';
import { KlaviyoService } from '@shared/services/klaviyo.service';
import { environment } from '@environments/environment';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';

@Component({
  selector: 'oes-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  public currentTheme = Theme.ODYSSEY;
  public theme = Theme;
  public embeddedDashboardEnabled = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private _themesService: ThemesService,
    private _klaviyoService: KlaviyoService,
    private _userService: UserService,
  ) {
    this.loadUserData();

    this._themesService.themeObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((theme: Theme) => {
      this.currentTheme = theme;
    });
    if (environment.name === 'prod' || environment.name === 'uat') {
      this._klaviyoService.initializeCurrentUser();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private loadUserData() {
    this._userService.getCurrentUser()
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user.organization.oapEnabled) {
          this.embeddedDashboardEnabled = false;
          return;
        }

        this.embeddedDashboardEnabled = user.organization.embeddedDashboardEnabled;
    });
  }
}

<div class="home" data-testid="proenergia-home-container">
  <img src="https://oes-hosted-images-public.s3.amazonaws.com/energia-logo-full.png" width="275px" />
  <div class="header-image">
    <div class="header-layer">
      <div class="proenergia-content-top">
        <div>
          <p>Supporting the expansion of the off-grid solar and clean cooking sectors in Mozambique with a focus on remote, underserved and vulnerable provinces with high poverty incidence.</p>
          <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply-now' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="proenergia-logo-container">

    <div class="logo-box">
      <h4>AN INITIATIVE OF</h4>
      <div class="logo-images">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/mireme-icon.png" width="100px" style="position: relative; bottom: 15px;" />
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/funae-logo.png" width="70px" height="70px" />
      </div>
    </div>

    <div class="logo-box">
      <h4>IMPLEMENTOR</h4>
      <div class="logo-images">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/bc-logo.png" width="280px" style="position: relative; top: 10px;" />
      </div>
    </div>

    <div class="logo-box">
      <h4>PARTNER</h4>
      <div class="logo-images">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/banco-mundial-logo.jpg" width="260x" style="position: relative; top: 8px;" />
      </div>
    </div>

  </div>
</div>
